import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { getAllSubscription, tokenDecode } from '../APIs/Call_API';
import Layout from './Layout';
import { Pagination } from '../commonComponents/Pagination';
import { getDateFormat } from '../commonComponents/common';

const Subscription = () => {

  const [admin, setAdmin] = useState(false)
  const [list, setList] = useState([])
  const [searchList, setSearchList] = useState([])

  const [searchName, setSearchName] = useState("")
  const [searchEmail, setSearchEmail] = useState("")
  const [paginationData, setPaginationData] = useState({})

  useEffect(() => {
    const { is_admin } = tokenDecode()
    setAdmin(is_admin)
    if (is_admin) {
      getData()
    }
  }, [])

  const getData = async (perPage = 10, page = 1, findData = {}) => {
    const requestData = {
      perPage,
      page,
      findData
    }
    const response = await getAllSubscription(requestData)
    setPaginationData({})
    if (response.status === 1) {
      setList(response.data)
      setSearchList(response.data)
      setPaginationData(response.paginationData)
    } else {
      setList([])
      setSearchList([])
      setPaginationData({})
      // toast.error(responseUser.message)
    }
  }

  const handleSearch = (e) => {
    e.preventDefault()
    let result = [];
    result = list.filter((data) =>
      (searchName.trim() && data.name.toString().toLowerCase().includes(searchName.trim().toLowerCase()))
      ||
      (searchEmail.trim() && data.email.toString().toLowerCase().includes(searchEmail.trim().toLowerCase()))
    )
    // ||  data.loan_amount.toString().toLowerCase().includes(value)
    setSearchList(result);

  }

  return (
    <Layout>
      <div className='mt-3'>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>No</th>
              <th>User</th>
              <th>Gifted User</th>
              <th>Plan</th>
              <th>Price</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <th>-</th>
              <th><Form.Control type="text" placeholder="Enter name" onChange={e => setSearchName(e.target.value)} value={searchName} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th className='d-grid gap-2 d-md-flex'>
                <Button variant="primary" onClick={handleSearch}>
                  Filter
                </Button>
                <Button variant="secondary" onClick={clearSearchData}>
                  Clear
                </Button>
              </th>
            </tr> */}
            {searchList.length > 0
              ?
              searchList.map((data, i) =>
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{data?.user_id?.name ?? "-"}</td>
                  <td>{data?.gifted_user_id?.name ?? "-"}</td>
                  <td>{data?.plan_id?.title ?? "-"}</td>
                  <td>{data?.price ?? "-"}</td>
                  <td>{data?.start_time ? getDateFormat(data.start_time) : "-"}</td>
                  <td>{data?.end_time ? getDateFormat(data.end_time) : "-"}</td>
                  <td>{data.created_at ? getDateFormat(data.created_at) : "-"}</td>
                </tr>
              )
              :
              <tr>
                <td colSpan={10} className="text-center">No record Found</td>
              </tr>
            }
          </tbody>
        </Table>

        {
          paginationData.total > 0 &&
          <Pagination
            className="mt-4 d-flex flex-row-reverse"
            itemsPerPage={paginationData.perPage}
            pagination={paginationData.total}
            getDataFunction={getData}
          />
        }
      </div>

    </Layout>

  )
}

export default Subscription