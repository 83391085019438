import * as React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { Form } from 'react-bootstrap'

const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana"
];
export default function HTMLEditor({ setDescription, description }) {
    const sortedFontOptions = [
        "Logical",
        "Salesforce Sans",
        "Garamond",
        "Sans-Serif",
        "Serif",
        "Times New Roman",
        "Helvetica",
        ...defaultFonts
    ].sort();

    return (
        <div>
            <SunEditor
                setContents={description}
                onChange={setDescription}
                setOptions={{
                    buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize"],
                        // ['paragraphStyle', 'blockquote'],
                        [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript"
                        ],
                        ["fontColor", "hiliteColor"],
                        ["align", "list", "lineHeight"],
                        ["outdent", "indent"],

                        ["table", "horizontalRule", "link", "image", "video"],
                        // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                        // ['imageGallery'], // You must add the "imageGalleryUrl".
                        // ["fullScreen", "showBlocks", "codeView"],
                        // ["preview", "print"],
                        ["removeFormat"]

                        // ['save', 'template'],
                        // '/', Line break
                    ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                    defaultTag: "div",
                    minHeight: "150px",
                    showPathLabel: false,
                    font: sortedFontOptions
                }}
            />
            {/* <h2>Example value output:</h2> */}
            {/* <textarea
                disabled
                value={JSON.stringify(value, null, 2)}
                style={{ width: "100%", resize: "none", height: "600px" }}
            /> */}
            <Form.Group>
                <Form.Label className="mb-0 mt-1">Output of HTML editor</Form.Label>
                <div className=" border border-secondary rounded p-1" dangerouslySetInnerHTML={{ __html: description }} />
            </Form.Group>
        </div>
    );
}
