import { useState } from 'react';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import Login from './components/Login';
import { tokenDecode } from './APIs/Call_API';
import ProtectedRoute from './protectedRoute/ProtectedRoute';
import UsersComponent from './components/UsersComponent';
import AudiosComponent from './components/AudiosComponent';
import CategoriesComponent from './components/CategoriesComponent';
import PremiumsComponent from './components/PremiumsComponent';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import CategoriesMeditation from './components/CategoriesMeditation';
import Meditation from './components/Meditation';
import Mood from './components/Mood';
import CategoriesQuran from './components/CategoriesQuran';
import Quran from './components/Quran';
import CategoriesBlog from './components/CategoriesBlog';
import Blog from './components/Blog';
import CategoriesQuote from './components/CategoriesQuote';
import Quote from './components/Quote';
import Plan from './components/Plan';
import Support from './components/Support';
import AdditionalInformation from './components/AdditionalInformation';
import Subscription from './components/Subscription';

function App() {

  const [token, setToken] = useState(localStorage.getItem("token"))

  return (
    <div>
      {/* className="App" */}
      <ToastContainer />
      <HashRouter>
        <Routes>
          <Route path="/" element={
            token ?
              <Navigate to={tokenDecode().is_admin ? "/users" : "/"} />
              : <Login />
          } />
          <Route element={<ProtectedRoute token={token} setToken={setToken} />}>
            <Route path="/users" element={<UsersComponent />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/audios" element={<AudiosComponent />} />
            <Route path="/categories" element={<CategoriesComponent />} />
            <Route path="/premiums" element={<PremiumsComponent />} />
            <Route path="/categories_meditation" element={<CategoriesMeditation />} />
            <Route path="/meditation" element={<Meditation />} />
            <Route path="/mood" element={<Mood />} />
            <Route path="/categories_quran" element={<CategoriesQuran />} />
            <Route path="/quran" element={<Quran />} />
            <Route path="/categories_blog" element={<CategoriesBlog />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/categories_quote" element={<CategoriesQuote />} />
            <Route path="/quote" element={<Quote />} />
            <Route path="/plan" element={<Plan />} />
            <Route path="/support" element={<Support />} />
            <Route path="/additional-information" element={<AdditionalInformation />} />
          </Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
