import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Table, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { tokenDecode, createPremium, deletePremium, getAllPremium, updatePremium, getAllCategory } from '../APIs/Call_API';
import Layout from './Layout';
import { Pagination } from '../commonComponents/Pagination';
import { getDateFormat } from '../commonComponents/common';
import { baseURL } from '../APIs/API_Config';

const PremiumsComponent = () => {

  const [modalShow, setModalShow] = useState(false);
  const [disable, setDisable] = useState(false)

  const [id, setId] = useState("")
  const [title, setTitle] = useState("")
  const [category_id, setCategory_id] = useState("")
  const [description, setDescription] = useState("")
  // const file_Ref = useRef(null)
  const [audio, setAudio] = useState(null);
  const [existAudio, setExistAudio] = useState("");
  // const image_Ref = useRef(null)
  const [image, setImage] = useState(null);
  const [existImage, setExistImage] = useState("");
  const [is_premium, setIs_premium] = useState(false)
  const [categoriesList, setCategoriesList] = useState([])

  const [admin, setAdmin] = useState(false)
  const [premiumsList, setPremiumsList] = useState([])
  const [searchList, setSearchList] = useState([])

  const [searchName, setSearchName] = useState("")
  const [searchEmail, setSearchEmail] = useState("")
  const [paginationData, setPaginationData] = useState({})

  useEffect(() => {
    const { is_admin } = tokenDecode()
    setAdmin(is_admin)
    if (is_admin) {
      getPremiumsData()
      getCategoriesData()
    }
  }, [])

  // console.log(file_1Ref.current);

  const getPremiumsData = async (perPage = 10, page = 1, findData = {}) => {
    const requestData = {
      perPage,
      page,
      findData
    }
    const responsePremium = await getAllPremium(requestData)
    if (responsePremium.status === 1) {
      setPremiumsList(responsePremium.data)
      setSearchList(responsePremium.data)
      setPaginationData(responsePremium.paginationData)
    } else {
      setPremiumsList([])
      setSearchList([])
      setPaginationData({})
    }
  }

  const getCategoriesData = async () => {
    const requestData = {
      perPage: 0,
      page: 0,
      // findData
    }
    const responseCategories = await getAllCategory(requestData)
    if (responseCategories.status === 1) {
      setCategoriesList(responseCategories.data)
    } else {
      setCategoriesList([])
    }
  }

  const closeModel = () => {
    setModalShow(false)
    setId("")
    setTitle("")
    setCategory_id("")
    setDescription("")
    setAudio(null)
    setExistAudio("")
    setImage(null)
    setExistImage("")
    setIs_premium(false)
  }

  const onSubmit = async e => {
    e.preventDefault()
    if (
      !title.trim() ||
      !category_id.trim() ||
      !description.trim() ||
      (!audio?.lastModified && !existAudio) ||
      (!image?.lastModified && !existImage)
    ) {
      return toast.error("Some fields are missing.")
    }

    if (!audio?.type.includes("audio") && !existAudio) {
      return toast.error("Please select only audio file.")
    }
    if (!image?.type.includes("image") && !existImage) {
      return toast.error("Please select only image.")
    }

    const formData = new FormData()
    formData.append("title", title)
    formData.append("category_id", category_id)
    formData.append("description", description)
    formData.append("is_premium", is_premium)
    if (!existAudio) formData.append("audio", audio)
    if (!existImage) formData.append("image", image)
    setDisable(true)
    let responseUploadFile = null
    if (id) {   // update category
      formData.append("id", id)
      responseUploadFile = await updatePremium(formData)
    } else {    // create category
      responseUploadFile = await createPremium(formData)
    }
    if (responseUploadFile.status === 1) {
      toast.success(responseUploadFile.message);
      setDisable(false)
      closeModel()
      getPremiumsData()
    } else {
      // toast.error(responseUploadFile.message)
      toast.error(<ErrorMessage message={responseUploadFile.message} />)
      setDisable(false)
    }
  }

  const ErrorMessage = ({ message }) => {
    const Comp = message.split(".").map(str => <p>{str}</p>);
    return <>{Comp}</>
  }

  const handleSearch = (e) => {
    e.preventDefault()
    /*   let result = [];
      result = premiumsList.filter((data) =>
        (searchName.trim() && data.name.toString().toLowerCase().includes(searchName.trim().toLowerCase()))
        ||
        (searchEmail.trim() && data.email.toString().toLowerCase().includes(searchEmail.trim().toLowerCase()))
      )
      // ||  data.loan_amount.toString().toLowerCase().includes(value)
      setSearchList(result); */

  }


  /* 
    const fileInput = file_1Ref.current

    const dataTransfer = new DataTransfer()
    let blob = await fetch("http://192.168.1.10:3001/audios/2022-09-20/2022-09-20_file_2.mp3").then(r => r.blob());
    // const file = new File(['Hello world!'], 'hello.txt', { type: 'text/plain' })
    const file = new File([blob], "file_example_MP3_700KB - Copy.mp3", { type: "audio/mpeg", lastModified: 1662803184124 })


    dataTransfer.items.add(file)
    // dataTransfer.items.add(file)

    fileInput.files = dataTransfer.files
    console.log(file, file_1Ref.current.value, file_1);
    return
     */

  const showSelectedFileDataInFileInput = async (file_ref, url, setFile, type) => {
    const dataTransfer = new DataTransfer()
    let blob = await fetch(`${baseURL}${url}`).then(r => r.blob());
    const file = new File([blob], url.split("/").slice(-1), { type: type, lastModified: Date.now() })
    console.log(file);
    dataTransfer.items.add(file)
    file_ref.current.files = dataTransfer.files
    setFile(file)
  }

  const editPremiumFunction = async (e, premiumData) => {
    e.preventDefault()
    setModalShow(true)
    setId(premiumData._id)
    setTitle(premiumData.title)
    setCategory_id(premiumData.category_id._id)
    setDescription(premiumData.description)
    // showSelectedFileDataInFileInput(file_Ref, premiumData.file, setFile, "audio/mpeg")
    // showSelectedFileDataInFileInput(image_Ref, premiumData.image, setImage, "image/jpeg")
    setExistAudio(premiumData.audio?.split("/").slice("-1"))
    setExistImage(premiumData.image?.split("/").slice("-1"))
    setIs_premium(premiumData.is_premium)
  }

  const deletePremiumFunction = async (e, id) => {
    e.preventDefault()
    const isDelete = window.confirm("Do you want to delete this?")
    if (isDelete) {
      const responseDeletePremium = await deletePremium({ id })
      if (responseDeletePremium.status === 1) {
        toast.success(responseDeletePremium.message);
        getPremiumsData()
      } else {
        toast.error(responseDeletePremium.message)
      }
    }
  }

  const LinkComponent = ({ audio }) => {
    // const generateUrl = `${baseURL}${audio}`
    // return <a href={audio} target="_blank" rel="noreferrer">
    //   {audio.split("/").slice(-1)}
    // </a>
    return <audio controls>
      <source src={audio} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  }
  const ImageComponent = ({ image }) => {
    // const generateUrl = `${baseURL}${image}`
    return <a href={image} target="_blank" rel="noreferrer">
      <img height={50} width={50} src={image} alt={image.split("/").slice(-1)} />
    </a>
  }

  return (
    <Layout>

      <div>
        <Button className="mt-4" variant="primary" type="button" onClick={() => setModalShow(true)}>
          Create Premium Music
        </Button>

        <Modal
          show={modalShow}
          backdrop="static"
          // size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {id ? "Edit Premium Music" : "Create Premium Music"}
            </Modal.Title>
          </Modal.Header>
          {/* style={{ maxHeight: "400px", overflowY: "scroll" }} */}
          <Modal.Body className="py-0">
            <Form onSubmit={onSubmit} id="upload-file">
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="mb-0 mt-1">Title</Form.Label>
                <Form.Control type="text" placeholder="Enter Title" onChange={e => setTitle(e.target.value)} value={title} />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mb-0 mt-1">Category</Form.Label>
                <Form.Select onChange={e => setCategory_id(e.target.value)} value={category_id} >
                  <option value="">Open this select menu</option>
                  {
                    categoriesList.length > 0 &&
                    categoriesList.map((category, i) =>
                      <option key={i} value={category._id}>{category.name}</option>
                    )
                  }
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label className="mb-0 mt-1">Description</Form.Label>
                <Form.Control type="text" as="textarea" rows={3} placeholder="Enter Description" onChange={e => setDescription(e.target.value)} value={description} />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mb-0 mt-1">Audio file</Form.Label>
                {
                  existAudio ?
                    <div className='d-flex'>
                      <span className="p2 flex-grow-1">{existAudio}</span>
                      <Button variant="danger" className="p2" onClick={() => setExistAudio("")}>Delete</Button>
                    </div>
                    :
                    <>
                      <Form.Control
                        type="file"
                        // ref={file_Ref}
                        onChange={e => setAudio(e.target.files[0])}
                        accept=".mp3"
                      />
                      <Form.Text className="text-muted mt-2">
                        Upload audio file only.
                      </Form.Text>
                    </>
                }
              </Form.Group>
              <Form.Group>
                <Form.Label className="mb-0 mt-1">Image</Form.Label>
                {
                  existImage ?
                    <div className='d-flex'>
                      <span className="p2 flex-grow-1">{existImage}</span>
                      <Button variant="danger" className="p2" onClick={() => setExistImage("")}>Delete</Button>
                    </div>
                    :
                    <>
                      <Form.Control
                        type="file"
                        // ref={image_Ref}
                        onChange={e => setImage(e.target.files[0])}
                        accept="image/*"
                      />
                      <Form.Text className="text-muted mt-2">
                        Upload image only.
                      </Form.Text>
                    </>
                }
              </Form.Group>
              <Form.Group>

                <Form.Label className="mb-0 mt-1">Premium</Form.Label>
                <Form.Check
                  // reverse
                  // style={{ align: "left !important" }}
                  type="switch"
                  id="custom-switch"
                  label="Check it for premium music"
                  onChange={e => setIs_premium(!is_premium)}
                  checked={is_premium}
                />
              </Form.Group>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => closeModel()} disabled={disable}>Close</Button>
            <Button variant="primary" type="submit" form="upload-file" disabled={disable}>{
              disable ?
                <>
                  <Spinner
                    className='me-2'
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </>
                :
                "Submit"
            }</Button>
          </Modal.Footer>
        </Modal>

      </div>

      <div className='mt-3'>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>No</th>
              <th>Title</th>
              <th>Description</th>
              <th>Category</th>
              <th>Premium</th>
              <th>Audio</th>
              <th>Image</th>
              <th>Created At</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <th>-</th>
              <th><Form.Control type="text" placeholder="Enter name" onChange={e => setSearchName(e.target.value)} value={searchName} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th className='d-grid gap-2 d-md-flex'>
                <Button variant="primary" onClick={handleSearch}>
                  Filter
                </Button>
                <Button variant="secondary" onClick={clearSearchData}>
                  Clear
                </Button>
              </th>
            </tr> */}
            {searchList.length > 0
              ?
              searchList.map((data, i) =>
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{data.title ?? "-"}</td>
                  <td>{data.description ?? "-"}</td>
                  <td>{data.category_id.name ?? "-"}</td>
                  <td>{data.is_premium ? "Yes" : "No"}</td>
                  <td>{data.audio ? <LinkComponent audio={data.audio} /> : "-"}</td>
                  <td>{data.image ? <ImageComponent image={data.image} /> : "-"}</td>
                  <td>{data.created_at ? getDateFormat(data.created_at) : "-"}</td>

                  {/* <td>{data.is_delete ? "True" : "False"}</td> */}
                  <td className='d-grid gap-2 d-md-flex'>
                    {admin ?
                      <>
                        <Button variant="primary" onClick={e => editPremiumFunction(e, data)}>
                          Edit
                        </Button>
                        <Button variant="danger" onClick={e => deletePremiumFunction(e, data._id)}>
                          Delete
                        </Button>
                      </>
                      : "-"
                    }
                  </td>
                </tr>
              )
              :
              <tr>
                <td colSpan={10} className="text-center">No record Found</td>
              </tr>
            }
          </tbody>
        </Table>

        {
          paginationData.total > 0 &&
          <Pagination
            className="mt-4 d-flex flex-row-reverse"
            itemsPerPage={paginationData.perPage}
            pagination={paginationData.total}
            getDataFunction={getPremiumsData}
          />
        }
      </div>

    </Layout>

  )
}

export default PremiumsComponent