import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Table, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { createPlan, deletePlan, getAllPlan, tokenDecode, updatePlan } from '../APIs/Call_API';
import Layout from './Layout';
import { Pagination } from '../commonComponents/Pagination';
import { getDateFormat } from '../commonComponents/common';

const Plan = () => {
  const [modalShow, setModalShow] = useState(false);
  const [disable, setDisable] = useState(false)

  const [id, setId] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [actual_price, setActual_price] = useState("")
  const [price, setPrice] = useState("")
  const [time_period, setTime_period] = useState("")
  const [access_module, setAccess_module] = useState([])

  const [admin, setAdmin] = useState(false)
  const [list, setList] = useState([])
  const [searchList, setSearchList] = useState([])

  const [searchName, setSearchName] = useState("")
  const [searchEmail, setSearchEmail] = useState("")
  const [paginationData, setPaginationData] = useState({})

  useEffect(() => {
    const { is_admin } = tokenDecode()
    setAdmin(is_admin)
    if (is_admin) {
      getData()
    }
  }, [])

  // console.log(file_1Ref.current);

  const getData = async (perPage = 10, page = 1, findData = {}) => {
    const requestData = {
      perPage,
      page,
      findData
    }
    const response = await getAllPlan(requestData)
    if (response.status === 1) {
      setList(response.data)
      setSearchList(response.data)
      setPaginationData(response.paginationData)
    } else {
      setList([])
      setSearchList([])
      setPaginationData({})
      // toast.error(response.message)
    }
  }

  const closeModel = () => {
    setModalShow(false)
    setId("")
    setTitle("")
    setDescription("")
    setActual_price("")
    setPrice("")
    setTime_period("")
    setAccess_module([])
  }

  const onSubmit = async e => {
    e.preventDefault()
    console.log(!price);
    if (
      !title.trim() ||
      !description.trim() ||
      !(actual_price >= 0) ||
      !(price >= 0) ||
      !time_period ||
      !access_module.length
    ) {
      return toast.error("Some fields are missing.")
    }

    const data = {
      title: title.trim(),
      description: description.trim(),
      actual_price,
      price,
      time_period,
      access_module,
    }

    setDisable(true)
    let response = null
    if (id) {   // update
      data.id = id
      response = await updatePlan(data)
    } else {    // create
      response = await createPlan(data)
    }
    if (response.status === 1) {
      toast.success(response.message);
      setDisable(false)
      closeModel()
      getData()
    } else {
      toast.error(response.message)
      // toast.error(<ErrorMessage message={response.message} />)
      setDisable(false)
    }
  }

  const ErrorMessage = ({ message }) => {
    const Comp = message.split(".").map(str => <p>{str}</p>);
    return <>{Comp}</>
  }

  const handleSearch = (e) => {
    e.preventDefault()
    let result = [];
    result = list.filter((data) =>
      (searchName.trim() && data.name.toString().toLowerCase().includes(searchName.trim().toLowerCase()))
      ||
      (searchEmail.trim() && data.email.toString().toLowerCase().includes(searchEmail.trim().toLowerCase()))
    )
    // ||  data.loan_amount.toString().toLowerCase().includes(value)
    setSearchList(result);

  }

  const editFunction = async (e, data) => {
    e.preventDefault()
    setModalShow(true)
    setId(data._id)
    setTitle(data.title)
    setDescription(data.description)
    setActual_price(data.actual_price)
    setPrice(data.price)
    setTime_period(data.time_period)
    setAccess_module(data?.access_module || [])
  }

  const deleteFunction = async (e, id) => {
    e.preventDefault()
    const isDelete = window.confirm("Do you want to delete this?")
    if (isDelete) {
      const responseDelete = await deletePlan({ id })
      if (responseDelete.status === 1) {
        toast.success(responseDelete.message);
        getData()
      } else {
        toast.error(responseDelete.message)
      }
    }
  }

  const handleAccessModule = (module) => {
    let moduleData = access_module
    if (access_module.includes(module)) {
      moduleData = access_module.filter(m => m !== module)
    } else {
      moduleData.push(module)
    }
    setAccess_module([...moduleData])
  }

  return (
    <Layout>

      <div>
        <Button className="mt-4" variant="primary" type="button" onClick={() => setModalShow(true)}>
          Create
        </Button>

        <Modal
          show={modalShow}
          backdrop="static"
          // size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {id ? "Edit" : "Create"}
            </Modal.Title>
          </Modal.Header>
          {/* style={{ maxHeight: "400px", overflowY: "scroll" }} */}
          <Modal.Body>
            <Form onSubmit={onSubmit} id="upload-file">
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="mb-0">Title</Form.Label>
                <input type="text" className="form-control" onChange={e => setTitle(e.target.value)} value={title} placeholder="Enter Title" />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mb-0 mt-1">Description</Form.Label>
                <Form.Control type="text" as="textarea" rows={3} placeholder="Enter Description" onChange={e => setDescription(e.target.value)} value={description} />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="mb-0">Actual Price</Form.Label>
                <input type="number" min="0" className="form-control" onChange={e => setActual_price(e.target.value)} value={actual_price} placeholder="Enter Actual Price" />
                <Form.Text className="text-muted mt-2">Enter 0 for trial plan.</Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="mb-0">Discount Price</Form.Label>
                <input type="number" min="0" className="form-control" onChange={e => setPrice(e.target.value)} value={price} placeholder="Enter Discount Price" />
                <Form.Text className="text-muted mt-2">Enter 0 for trial plan.</Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="mb-0">Time Period (In month)</Form.Label>
                <input type="number" min="1" className="form-control" onChange={e => setTime_period(e.target.value)} value={time_period} placeholder="Enter Time Period (In month)" />
              </Form.Group>
              <Form.Group>
                <Form.Label className="mb-0 mt-1 pe-2">Access Module:</Form.Label>
                <Form.Check
                  // inline
                  label="Category Meditation"
                  checked={access_module?.includes("category-meditation")}
                  onChange={() => handleAccessModule("category-meditation")}
                  name="access_module"
                  type="checkbox"
                  id="inline-category-meditation"
                />
                <Form.Check
                  // inline
                  label="Category Blog"
                  checked={access_module?.includes("category-blog")}
                  onChange={() => handleAccessModule("category-blog")}
                  name="access_module"
                  type="checkbox"
                  id="inline-category-blog"
                />
                <Form.Check
                  // inline
                  label="Category Quotes"
                  checked={access_module?.includes("category-quotes")}
                  onChange={() => handleAccessModule("category-quotes")}
                  name="access_module"
                  type="checkbox"
                  id="inline-category-quotes"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => closeModel()} disabled={disable}>Close</Button>
            <Button variant="primary" type="submit" form="upload-file" disabled={disable}>{
              disable ?
                <>
                  <Spinner
                    className='me-2'
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </>
                :
                "Submit"
            }</Button>
          </Modal.Footer>
        </Modal>

      </div>

      <div className='mt-3'>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>No</th>
              <th>Title</th>
              <th>Actual Price</th>
              <th>Discount Price</th>
              <th>Time Period (In month)</th>
              <th>Created At</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <th>-</th>
              <th><Form.Control type="text" placeholder="Enter name" onChange={e => setSearchName(e.target.value)} value={searchName} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th className='d-grid gap-2 d-md-flex'>
                <Button variant="primary" onClick={handleSearch}>
                  Filter
                </Button>
                <Button variant="secondary" onClick={clearSearchData}>
                  Clear
                </Button>
              </th>
            </tr> */}
            {searchList.length > 0
              ?
              searchList.map((data, i) =>
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{data.title ?? "-"}</td>
                  <td>{data.actual_price ?? "-"}</td>
                  <td>{data.price ?? "-"}</td>
                  <td>{data.time_period ?? "-"}</td>
                  <td>{data.created_at ? getDateFormat(data.created_at) : "-"}</td>

                  <td className='d-grid gap-2 d-md-flex'>
                    {admin ?
                      <>
                        <Button variant="primary" onClick={e => editFunction(e, data)}>
                          Edit
                        </Button>
                        <Button variant="danger" onClick={e => deleteFunction(e, data._id)}>
                          Delete
                        </Button>
                      </>
                      : "-"
                    }
                  </td>
                </tr>
              )
              :
              <tr>
                <td colSpan={10} className="text-center">No record Found</td>
              </tr>
            }
          </tbody>
        </Table>

        {
          paginationData.total > 0 &&
          <Pagination
            className="mt-4 d-flex flex-row-reverse"
            itemsPerPage={paginationData.perPage}
            pagination={paginationData.total}
            getDataFunction={getData}
          />
        }
      </div>
    </Layout>

  )
}

export default Plan