import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { loginAPI, tokenDecode } from '../APIs/Call_API'
import { APP_NAME } from '../commonComponents/common'

const Login = () => {

    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const onSubmit = async e => {
        e.preventDefault()
        const requestData = {
            email: email.trim(),
            password: password.trim()
        }
        const loginResponse = await loginAPI(requestData)
        if (loginResponse.status === 1) {
            localStorage.setItem('token', loginResponse.token)
            toast.success(loginResponse.message)
            const { is_admin, is_delete } = tokenDecode()
            if (is_admin) {
                navigate("/users")
            }
        } else {
            toast.error(loginResponse.message)
        }
    }

    return (
        <div>
            <div className='position-absolute top-50 start-50 translate-middle'>
                <p className="fs-1 fw-bold">{APP_NAME}</p>
                <Form onSubmit={onSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" required onChange={e => setEmail(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" required onChange={e => setPassword(e.target.value)} />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Login
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default Login