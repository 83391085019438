import axios from "axios";

// export const baseURL = process.env.REACT_APP_BASEURL
export const baseURL = window.location.origin

const serverInstance = axios.create({
    baseURL: `${baseURL}/admin`,
    // baseURL: `${window.location.origin}/api`,
})

// console.log(serverInstance.defaults.baseURL)
// export const baseURL = serverInstance.getUri()

export default serverInstance