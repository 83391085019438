import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { deleteUser, getAllUser, tokenDecode } from '../APIs/Call_API';
import Layout from './Layout';
import { Pagination } from '../commonComponents/Pagination';
import { getDateFormat } from '../commonComponents/common';

const UsersComponent = () => {

  const [admin, setAdmin] = useState(false)
  const [usersList, setUsersList] = useState([])
  const [searchList, setSearchList] = useState([])

  const [searchName, setSearchName] = useState("")
  const [searchEmail, setSearchEmail] = useState("")
  const [paginationData, setPaginationData] = useState({})

  useEffect(() => {
    const { is_admin } = tokenDecode()
    setAdmin(is_admin)
    if (is_admin) {
      getUsersData()
    }
  }, [])

  const getUsersData = async (perPage = 10, page = 1, findData = {}) => {
    const requestData = {
      perPage,
      page,
      findData
    }
    const responseUser = await getAllUser(requestData)
    setPaginationData({})
    if (responseUser.status === 1) {
      setUsersList(responseUser.data)
      setSearchList(responseUser.data)
      setPaginationData(responseUser.paginationData)
    } else {
      setUsersList([])
      setSearchList([])
      setPaginationData({})
      // toast.error(responseUser.message)
    }
  }

  const handleSearch = (e) => {
    e.preventDefault()
    let result = [];
    result = usersList.filter((data) =>
      (searchName.trim() && data.name.toString().toLowerCase().includes(searchName.trim().toLowerCase()))
      ||
      (searchEmail.trim() && data.email.toString().toLowerCase().includes(searchEmail.trim().toLowerCase()))
    )
    // ||  data.loan_amount.toString().toLowerCase().includes(value)
    setSearchList(result);

  }

  const deleteUserFunction = async (e, id) => {
    e.preventDefault()
    const isDelete = window.confirm("Do you want to delete this?")
    if (isDelete) {
      const responseDeleteUser = await deleteUser({ id })
      if (responseDeleteUser.status === 1) {
        toast.success(responseDeleteUser.message);
        getUsersData()
      } else {
        toast.error(responseDeleteUser.message)
      }
    }
  }

  return (
    <Layout>
      <div className='mt-3'>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Phone No.</th>
              <th>Gender</th>
              <th>Email</th>
              <th>Created At</th>
              <th>Deleted Status</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <th>-</th>
              <th><Form.Control type="text" placeholder="Enter name" onChange={e => setSearchName(e.target.value)} value={searchName} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th className='d-grid gap-2 d-md-flex'>
                <Button variant="primary" onClick={handleSearch}>
                  Filter
                </Button>
                <Button variant="secondary" onClick={clearSearchData}>
                  Clear
                </Button>
              </th>
            </tr> */}
            {searchList.length > 0
              ?
              searchList.map((data, i) =>
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{data.name ?? "-"}</td>
                  <td>{data.phone ?? "-"}</td>
                  <td>{data.gender ?? "-"}</td>
                  <td>{data.email ?? "-"}</td>
                  <td>{data.created_at ? getDateFormat(data.created_at) : "-"}</td>
                  <td>{data.is_delete ? "True" : "False"}</td>
                  <td className='d-grid gap-2 d-md-flex'>
                    {admin ?
                      <>
                        <Button variant={!data.is_delete ? "danger" : "secondary"} onClick={e => deleteUserFunction(e, data._id)} disabled={data.is_delete}>
                          Delete
                        </Button>
                      </>
                      : "-"
                    }
                  </td>
                </tr>
              )
              :
              <tr>
                <td colSpan={10} className="text-center">No record Found</td>
              </tr>
            }
          </tbody>
        </Table>

        {
          paginationData.total > 0 &&
          <Pagination
            className="mt-4 d-flex flex-row-reverse"
            itemsPerPage={paginationData.perPage}
            pagination={paginationData.total}
            getDataFunction={getUsersData}
          />
        }
      </div>

    </Layout>

  )
}

export default UsersComponent