import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal, Table, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { createAdditionalInformation, deleteAdditionalInformation, getAllAdditionalInformation, tokenDecode, updateAdditionalInformation } from '../APIs/Call_API';
import Layout from './Layout';
import { Pagination } from '../commonComponents/Pagination';
import { getDateFormat } from '../commonComponents/common';
import HTMLEditor from '../commonComponents/HTMLEditor';
import moment from 'moment';

const AdditionalInformation = () => {
    const [modalShow, setModalShow] = useState(false);
    const [disable, setDisable] = useState(false)

    const [id, setId] = useState("")
    const [title, setTitle] = useState("")
    const [text, setText] = useState("")
    const [type, setType] = useState("link")

    const [admin, setAdmin] = useState(false)
    const [list, setList] = useState([])
    const [searchList, setSearchList] = useState([])

    const [searchName, setSearchName] = useState("")
    const [searchEmail, setSearchEmail] = useState("")
    const [paginationData, setPaginationData] = useState({})

    const getDate = (dateFormat) => moment(dateFormat).format("YYYY-MM-DD")

    useEffect(() => {
        const { is_admin } = tokenDecode()
        setAdmin(is_admin)
        if (is_admin) {
            getData()
        }
    }, [])

    // console.log(file_1Ref.current);

    const getData = async (perPage = 10, page = 1, findData = {}) => {
        const requestData = {
            perPage,
            page,
            findData
        }
        const response = await getAllAdditionalInformation(requestData)
        if (response.status === 1) {
            setList(response.data)
            setSearchList(response.data)
            setPaginationData(response.paginationData)
        } else {
            setList([])
            setSearchList([])
            setPaginationData({})
            // toast.error(response.message)
        }
    }

    const closeModel = () => {
        setModalShow(false)
        setId("")
        setTitle("")
        setText("")
        setType("link")
    }

    const onSubmit = async e => {
        e.preventDefault()

        if (
            !title.trim() ||
            !text.trim()
        ) {
            return toast.error("Some fields are missing.")
        }

        const data = {
            title: title.trim(),
            text: text.trim(),
            type,
        }
        setDisable(true)
        let response = null
        if (id) {   // update
            data.id = id
            response = await updateAdditionalInformation(data)
        } else {    // create
            response = await createAdditionalInformation(data)
        }
        if (response.status === 1) {
            toast.success(response.message);
            setDisable(false)
            closeModel()
            getData()
        } else {
            toast.error(response.message)
            // toast.error(<ErrorMessage message={response.message} />)
            setDisable(false)
        }
    }

    const ErrorMessage = ({ message }) => {
        const Comp = message.split(".").map(str => <p>{str}</p>);
        return <>{Comp}</>
    }

    const handleSearch = (e) => {
        e.preventDefault()
        let result = [];
        result = list.filter((data) =>
            (searchName.trim() && data.name.toString().toLowerCase().includes(searchName.trim().toLowerCase()))
            ||
            (searchEmail.trim() && data.email.toString().toLowerCase().includes(searchEmail.trim().toLowerCase()))
        )
        // ||  data.loan_amount.toString().toLowerCase().includes(value)
        setSearchList(result);

    }

    const editFunction = async (e, data) => {
        e.preventDefault()
        setModalShow(true)
        setId(data._id)
        setTitle(data.title)
        setText(data.text)
        setType(data.type)
    }

    const deleteFunction = async (e, id) => {
        e.preventDefault()
        const isDelete = window.confirm("Do you want to delete this?")
        if (isDelete) {
            const responseDelete = await deleteAdditionalInformation({ id })
            if (responseDelete.status === 1) {
                toast.success(responseDelete.message);
                getData()
            } else {
                toast.error(responseDelete.message)
            }
        }
    }

    return (
        <Layout>

            <div>
                <Button className="mt-4" variant="primary" type="button" onClick={() => setModalShow(true)}>
                    Create
                </Button>

                <Modal
                    show={modalShow}
                    backdrop="static"
                    // size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {id ? "Edit" : "Create"}
                        </Modal.Title>
                    </Modal.Header>
                    {/* style={{ maxHeight: "400px", overflowY: "scroll" }} */}
                    <Modal.Body>
                        <Form onSubmit={onSubmit} id="upload-file">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label className="mb-0 mt-1">Title</Form.Label>
                                <input type="text" className="form-control" onChange={e => setTitle(e.target.value)} value={title} placeholder="Enter Title" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="mb-0 mt-1 pe-2">Type:</Form.Label>
                                <Form.Check
                                    inline
                                    label="Link"
                                    checked={type === "link"}
                                    onChange={() => setType("link")}
                                    name="type"
                                    type="radio"
                                    id="inline-link"
                                />
                                <Form.Check
                                    inline
                                    label="HTML"
                                    checked={type === "html"}
                                    onChange={() => setType("html")}
                                    name="type"
                                    type="radio"
                                    id="inline-html"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="mb-0 mt-1">{type === "link" ? "Link" : "HTML"}</Form.Label>
                                {type === "link"
                                    ? <Form.Control type="text" as="textarea" rows={3} placeholder="Enter Link" onChange={e => setText(e.target.value)} value={text} />
                                    : <HTMLEditor setDescription={setText} description={text} />
                                }

                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModel()} disabled={disable}>Close</Button>
                        <Button variant="primary" type="submit" form="upload-file" disabled={disable}>{
                            disable ?
                                <>
                                    <Spinner
                                        className='me-2'
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading...
                                </>
                                :
                                "Submit"
                        }</Button>
                    </Modal.Footer>
                </Modal>

            </div>

            <div className='mt-3'>
                <Table bordered hover responsive>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Title</th>
                            <th>Type</th>
                            <th>Created At</th>
                            <th>Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr>
              <th>-</th>
              <th><Form.Control type="text" placeholder="Enter name" onChange={e => setSearchName(e.target.value)} value={searchName} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th className='d-grid gap-2 d-md-flex'>
                <Button variant="primary" onClick={handleSearch}>
                  Filter
                </Button>
                <Button variant="secondary" onClick={clearSearchData}>
                  Clear
                </Button>
              </th>
            </tr> */}
                        {searchList.length > 0
                            ?
                            searchList.map((data, i) =>
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{data.title ?? "-"}</td>
                                    <td>{data.type ?? "-"}</td>
                                    <td>{data.created_at ? getDateFormat(data.created_at) : "-"}</td>

                                    <td className='d-grid gap-2 d-md-flex'>
                                        {admin ?
                                            <>
                                                <Button variant="primary" onClick={e => editFunction(e, data)}>
                                                    Edit
                                                </Button>
                                                <Button variant="danger" onClick={e => deleteFunction(e, data._id)}>
                                                    Delete
                                                </Button>
                                            </>
                                            : "-"
                                        }
                                    </td>
                                </tr>
                            )
                            :
                            <tr>
                                <td colSpan={10} className="text-center">No record Found</td>
                            </tr>
                        }
                    </tbody>
                </Table>

                {
                    paginationData.total > 0 &&
                    <Pagination
                        className="mt-4 d-flex flex-row-reverse"
                        itemsPerPage={paginationData.perPage}
                        pagination={paginationData.total}
                        getDataFunction={getData}
                    />
                }
            </div>
        </Layout>

    )
}

export default AdditionalInformation