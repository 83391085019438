import serverInstance from "./API_Config";

export const GET = async (endPoint) => {
    try {
        const { data } = await serverInstance.get(endPoint)
        return data
    } catch (error) {
        return errorFunction(error)
    }
}

export const POST = async (endPoint, response) => {
    try {
        const { data } = await serverInstance.post(endPoint, response)
        return data
    } catch (error) {
        return errorFunction(error)
    }
}

export const PATCH = async (endPoint, response) => {
    try {
        const { data } = await serverInstance.patch(endPoint, response)
        return data
    } catch (error) {
        return errorFunction(error)
    }
}

export const DELETE = async (endPoint) => {
    try {
        const { data } = await serverInstance.delete(endPoint)
        return data
    } catch (error) {
        return errorFunction(error)
    }
}

const errorFunction = error => {
    const statusArray = [401, 403]
    if (statusArray.includes(error.response.status)) {
        setTimeout(() => {
            localStorage.removeItem("token")
            window.location.href = "/"
        }, 3000)
        return error.response.data
    } else {
        return error.response.data
    }
}