import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal, Table, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { createBlog, deleteBlog, getAllBlog, getAllBlogCategory, tokenDecode, updateBlog } from '../APIs/Call_API';
import Layout from './Layout';
import { Pagination } from '../commonComponents/Pagination';
import { getDateFormat } from '../commonComponents/common';
import HTMLEditor from '../commonComponents/HTMLEditor';
import moment from 'moment';

const Blog = () => {
    const [modalShow, setModalShow] = useState(false);
    const [disable, setDisable] = useState(false)

    const [id, setId] = useState("")
    const [category_id, setCategory_id] = useState("")
    const [categoriesList, setCategoriesList] = useState([])
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [reading_time, setReading_time] = useState("")
    const [date, setDate] = useState(null)
    const [type, setType] = useState("image")
    const imageRef = useRef()
    const [image, setImage] = useState(null);
    const [existImage, setExistImage] = useState("");

    const [admin, setAdmin] = useState(false)
    const [list, setList] = useState([])
    const [searchList, setSearchList] = useState([])

    const [searchName, setSearchName] = useState("")
    const [searchEmail, setSearchEmail] = useState("")
    const [paginationData, setPaginationData] = useState({})

    const getDate = (dateFormat) => moment(dateFormat).format("YYYY-MM-DD")

    useEffect(() => {
        const { is_admin } = tokenDecode()
        setAdmin(is_admin)
        if (is_admin) {
            getData()
            getCategoriesData()
        }
    }, [])

    // console.log(file_1Ref.current);

    const getData = async (perPage = 10, page = 1, findData = {}) => {
        const requestData = {
            perPage,
            page,
            findData
        }
        const response = await getAllBlog(requestData)
        if (response.status === 1) {
            setList(response.data)
            setSearchList(response.data)
            setPaginationData(response.paginationData)
        } else {
            setList([])
            setSearchList([])
            setPaginationData({})
            // toast.error(response.message)
        }
    }

    const getCategoriesData = async () => {
        const requestData = {
            perPage: 0,
            page: 0,
            // findData
        }
        const responseCategories = await getAllBlogCategory(requestData)
        if (responseCategories.status === 1) {
            setCategoriesList(responseCategories.data)
        } else {
            setCategoriesList([])
        }
    }

    const closeModel = () => {
        setModalShow(false)
        setId("")
        setTitle("")
        setDescription("")
        setDate(null)
        setReading_time("")
        setType("image")
        setImage(null)
        setExistImage("")
        setCategory_id("")
    }

    const onSubmit = async e => {
        e.preventDefault()

        if (
            !category_id.trim() ||
            !title.trim() ||
            !description.trim() ||
            !date.trim() ||
            !reading_time ||
            (!image?.lastModified && !existImage)
        ) {
            return toast.error("Some fields are missing.")
        }

        if (type === "image" && !image?.type.includes("image") && !existImage) {
            return toast.error(`Please select only ${type}.`)
        }
        if (type === "video" && !image?.type.includes("video") && !existImage) {
            return toast.error(`Please select only ${type}.`)
        }

        const data = {
            category_id: category_id.trim(),
            title: title.trim(),
            description: description.trim(),
            date,
            reading_time,
            type,
        }
        if (!existImage) data.image = image
        setDisable(true)
        let response = null
        if (id) {   // update
            data.id = id
            const formData = new FormData()
            Object.entries(data).map(([key, value]) => formData.append(key, value))
            response = await updateBlog(formData)
        } else {    // create
            const formData = new FormData()
            Object.entries(data).map(([key, value]) => formData.append(key, value))
            response = await createBlog(formData)
        }
        if (response.status === 1) {
            toast.success(response.message);
            setDisable(false)
            closeModel()
            getData()
        } else {
            toast.error(response.message)
            // toast.error(<ErrorMessage message={response.message} />)
            setDisable(false)
        }
    }

    const ErrorMessage = ({ message }) => {
        const Comp = message.split(".").map(str => <p>{str}</p>);
        return <>{Comp}</>
    }

    const handleSearch = (e) => {
        e.preventDefault()
        let result = [];
        result = list.filter((data) =>
            (searchName.trim() && data.name.toString().toLowerCase().includes(searchName.trim().toLowerCase()))
            ||
            (searchEmail.trim() && data.email.toString().toLowerCase().includes(searchEmail.trim().toLowerCase()))
        )
        // ||  data.loan_amount.toString().toLowerCase().includes(value)
        setSearchList(result);

    }

    const editFunction = async (e, data) => {
        e.preventDefault()
        setModalShow(true)
        setId(data._id)
        setCategory_id(data.category_id._id)
        setTitle(data.title)
        setDescription(data.description)
        setDate(getDate(data.date))
        setReading_time(data.reading_time)
        setType(data.type)
        setExistImage(data.image?.split("/").slice("-1"))
    }

    const deleteFunction = async (e, id) => {
        e.preventDefault()
        const isDelete = window.confirm("Do you want to delete this?")
        if (isDelete) {
            const responseDelete = await deleteBlog({ id })
            if (responseDelete.status === 1) {
                toast.success(responseDelete.message);
                getData()
            } else {
                toast.error(responseDelete.message)
            }
        }
    }

    const ImageComponent = ({ type, image }) => {
        // const generateUrl = `${baseURL}${image}`
        const extension = image.split(".").slice(-1)[0]
        return <>
            {type === "image"
                ?
                <a href={image} target="_blank" rel="noreferrer">
                    <img height={50} width={50} src={image} alt={image.split("/").slice(-1)} />
                </a>
                :
                <video height="120" controls>
                    <source src={image} type={`video/${extension}`} />
                    Your browser does not support the video tag.
                </video>
            }
        </>
    }

    const handleType = (type) => {
        setType(type)
        setImage(null)
        setExistImage("")
        let ref = imageRef?.current
        if (ref?.value) ref.value = ""
    }

    return (
        <Layout>

            <div>
                <Button className="mt-4" variant="primary" type="button" onClick={() => setModalShow(true)}>
                    Create
                </Button>

                <Modal
                    show={modalShow}
                    backdrop="static"
                    // size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {id ? "Edit" : "Create"}
                        </Modal.Title>
                    </Modal.Header>
                    {/* style={{ maxHeight: "400px", overflowY: "scroll" }} */}
                    <Modal.Body>
                        <Form onSubmit={onSubmit} id="upload-file">
                            <Form.Group>
                                <Form.Label className="mb-0 mt-1">Category</Form.Label>
                                <Form.Select onChange={e => setCategory_id(e.target.value)} value={category_id} >
                                    <option value="">Open this select menu</option>
                                    {
                                        categoriesList.length > 0 &&
                                        categoriesList.map((category, i) =>
                                            <option key={i} value={category._id}>{category.title}</option>
                                        )
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label className="mb-0 mt-1">Title</Form.Label>
                                <input type="text" className="form-control" onChange={e => setTitle(e.target.value)} value={title} placeholder="Enter Title" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="mb-0 mt-1">Description</Form.Label>
                                {/* <Form.Control type="text" as="textarea" rows={3} placeholder="Enter Description" onChange={e => setDescription(e.target.value)} value={description} /> */}
                                <HTMLEditor setDescription={setDescription} description={description} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="mb-0 mt-1">Date</Form.Label>
                                <Form.Control type="date" className="form-control" onChange={e => setDate(e.target.value)} value={date} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="mb-0 mt-1">Reading Time (In minutes)</Form.Label>
                                <Form.Control type="number" min="1" placeholder="Enter Reading Time (In minutes)" onChange={e => setReading_time(e.target.value)} value={reading_time} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="mb-0 mt-1 pe-2">Type:</Form.Label>
                                <Form.Check
                                    inline
                                    label="Image"
                                    checked={type === "image"}
                                    onChange={() => handleType("image")}
                                    name="type"
                                    type="radio"
                                    id="inline-image"
                                />
                                <Form.Check
                                    inline
                                    label="Video"
                                    checked={type === "video"}
                                    onChange={() => handleType("video")}
                                    name="type"
                                    type="radio"
                                    id="inline-video"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="mb-0 mt-1">{type === "image" ? "Image" : "Video"}</Form.Label>
                                {
                                    existImage ?
                                        <div className='d-flex'>
                                            <span className="p2 flex-grow-1">{existImage}</span>
                                            <Button variant="danger" className="p2" onClick={() => setExistImage("")}>Delete</Button>
                                        </div>
                                        :
                                        <>
                                            <Form.Control
                                                type="file"
                                                ref={imageRef}
                                                onChange={e => setImage(e.target.files[0])}
                                                accept={`${type}/*`}
                                            />
                                            <Form.Text className="text-muted mt-2">
                                                {`Upload ${type} only.`}
                                            </Form.Text>
                                        </>
                                }
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModel()} disabled={disable}>Close</Button>
                        <Button variant="primary" type="submit" form="upload-file" disabled={disable}>{
                            disable ?
                                <>
                                    <Spinner
                                        className='me-2'
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading...
                                </>
                                :
                                "Submit"
                        }</Button>
                    </Modal.Footer>
                </Modal>

            </div>

            <div className='mt-3'>
                <Table bordered hover responsive>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Category</th>
                            <th>Title</th>
                            <th>Date</th>
                            <th>Reading Time (In minutes)</th>
                            <th>Type</th>
                            <th>Image/Video</th>
                            <th>Created At</th>
                            <th>Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr>
              <th>-</th>
              <th><Form.Control type="text" placeholder="Enter name" onChange={e => setSearchName(e.target.value)} value={searchName} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th className='d-grid gap-2 d-md-flex'>
                <Button variant="primary" onClick={handleSearch}>
                  Filter
                </Button>
                <Button variant="secondary" onClick={clearSearchData}>
                  Clear
                </Button>
              </th>
            </tr> */}
                        {searchList.length > 0
                            ?
                            searchList.map((data, i) =>
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{data.category_id.title ?? "-"}</td>
                                    <td>{data.title ?? "-"}</td>
                                    <td>{data.date ? getDate(data.date) : "-"}</td>
                                    <td>{data.reading_time ?? "-"}</td>
                                    <td>{data.type ?? "-"}</td>
                                    <td>{data.image ? <ImageComponent type={data.type} image={data.image} /> : "-"}</td>
                                    <td>{data.created_at ? getDateFormat(data.created_at) : "-"}</td>

                                    <td className='d-grid gap-2 d-md-flex'>
                                        {admin ?
                                            <>
                                                <Button variant="primary" onClick={e => editFunction(e, data)}>
                                                    Edit
                                                </Button>
                                                <Button variant="danger" onClick={e => deleteFunction(e, data._id)}>
                                                    Delete
                                                </Button>
                                            </>
                                            : "-"
                                        }
                                    </td>
                                </tr>
                            )
                            :
                            <tr>
                                <td colSpan={10} className="text-center">No record Found</td>
                            </tr>
                        }
                    </tbody>
                </Table>

                {
                    paginationData.total > 0 &&
                    <Pagination
                        className="mt-4 d-flex flex-row-reverse"
                        itemsPerPage={paginationData.perPage}
                        pagination={paginationData.total}
                        getDataFunction={getData}
                    />
                }
            </div>
        </Layout>

    )
}

export default Blog