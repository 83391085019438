import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Table, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { createQuran, deleteQuran, getAllQuran, getAllQuranCategory, tokenDecode, updateQuran } from '../APIs/Call_API';
import Layout from './Layout';
import { Pagination } from '../commonComponents/Pagination';
import { getDateFormat } from '../commonComponents/common';
import HTMLEditor from '../commonComponents/HTMLEditor';

const Quran = () => {
    const [modalShow, setModalShow] = useState(false);
    const [disable, setDisable] = useState(false)

    const [id, setId] = useState("")
    const [category_id, setCategory_id] = useState("")
    const [categoriesList, setCategoriesList] = useState([])
    const [title, setTitle] = useState("")
    const [urdu_title, setUrdu_title] = useState("")
    const [description, setDescription] = useState("")
    const [urdu_description, setUrdu_description] = useState("")
    const [audio, setAudio] = useState(null);
    const [existAudio, setExistAudio] = useState("");
    const [urdu_audio, setUrdu_audio] = useState(null);
    const [existUrdu_audio, setExistUrdu_audio] = useState("");
    const [image, setImage] = useState(null);
    const [existImage, setExistImage] = useState("");

    const [admin, setAdmin] = useState(false)
    const [list, setList] = useState([])
    const [searchList, setSearchList] = useState([])

    const [searchName, setSearchName] = useState("")
    const [searchEmail, setSearchEmail] = useState("")
    const [paginationData, setPaginationData] = useState({})

    useEffect(() => {
        const { is_admin } = tokenDecode()
        setAdmin(is_admin)
        if (is_admin) {
            getData()
            getCategoriesData()
        }
    }, [])

    // console.log(file_1Ref.current);

    const getData = async (perPage = 10, page = 1, findData = {}) => {
        const requestData = {
            perPage,
            page,
            findData
        }
        const response = await getAllQuran(requestData)
        if (response.status === 1) {
            setList(response.data)
            setSearchList(response.data)
            setPaginationData(response.paginationData)
        } else {
            setList([])
            setSearchList([])
            setPaginationData({})
            // toast.error(response.message)
        }
    }

    const getCategoriesData = async () => {
        const requestData = {
            perPage: 0,
            page: 0,
            // findData
        }
        const responseCategories = await getAllQuranCategory(requestData)
        if (responseCategories.status === 1) {
            setCategoriesList(responseCategories.data)
        } else {
            setCategoriesList([])
        }
    }

    const closeModel = () => {
        setModalShow(false)
        setId("")
        setTitle("")
        setDescription("")
        setAudio(null)
        setExistAudio("")
        setImage(null)
        setExistImage("")
        setCategory_id("")

        setUrdu_title("")
        setUrdu_description("")
        setUrdu_audio(null)
        setExistUrdu_audio("")
    }

    const onSubmit = async e => {
        e.preventDefault()

        if (
            !category_id.trim() ||
            !title.trim() ||
            !urdu_title.trim() ||
            !description.trim() ||
            !urdu_description.trim() ||
            (!audio?.lastModified && !existAudio) ||
            (!urdu_audio?.lastModified && !existUrdu_audio) ||
            (!image?.lastModified && !existImage)
        ) {
            return toast.error("Some fields are missing.")
        }

        if (!audio?.type.includes("audio") && !existAudio) {
            return toast.error("Please select only audio file.")
        }
        if (!urdu_audio?.type.includes("audio") && !existUrdu_audio) {
            return toast.error("Please select only audio file.")
        }
        if (!image?.type.includes("image") && !existImage) {
            return toast.error("Please select only image.")
        }

        const data = {
            category_id: category_id.trim(),
            title: title.trim(),
            description: description.trim(),
            urdu_title: urdu_title.trim(),
            urdu_description: urdu_description.trim(),
        }
        if (!existAudio) data.audio = audio
        if (!existUrdu_audio) data.urdu_audio = urdu_audio
        if (!existImage) data.image = image
        setDisable(true)
        let response = null
        if (id) {   // update
            data.id = id
            const formData = new FormData()
            Object.entries(data).map(([key, value]) => formData.append(key, value))
            response = await updateQuran(formData)
        } else {    // create
            const formData = new FormData()
            Object.entries(data).map(([key, value]) => formData.append(key, value))
            response = await createQuran(formData)
        }
        if (response.status === 1) {
            toast.success(response.message);
            setDisable(false)
            closeModel()
            getData()
        } else {
            toast.error(response.message)
            // toast.error(<ErrorMessage message={response.message} />)
            setDisable(false)
        }
    }

    const ErrorMessage = ({ message }) => {
        const Comp = message.split(".").map(str => <p>{str}</p>);
        return <>{Comp}</>
    }

    const handleSearch = (e) => {
        e.preventDefault()
        let result = [];
        result = list.filter((data) =>
            (searchName.trim() && data.name.toString().toLowerCase().includes(searchName.trim().toLowerCase()))
            ||
            (searchEmail.trim() && data.email.toString().toLowerCase().includes(searchEmail.trim().toLowerCase()))
        )
        // ||  data.loan_amount.toString().toLowerCase().includes(value)
        setSearchList(result);

    }

    const editFunction = async (e, data) => {
        e.preventDefault()
        setModalShow(true)
        setId(data._id)
        setCategory_id(data.category_id._id)
        setTitle(data.title)
        setUrdu_title(data?.urdu_title)
        setDescription(data.description)
        setUrdu_description(data?.urdu_description)
        setExistAudio(data.audio?.split("/").slice("-1"))
        setExistUrdu_audio(data?.urdu_audio?.split("/").slice("-1"))
        setExistImage(data.image?.split("/").slice("-1"))
    }

    const deleteFunction = async (e, id) => {
        e.preventDefault()
        const isDelete = window.confirm("Do you want to delete this?")
        if (isDelete) {
            const responseDelete = await deleteQuran({ id })
            if (responseDelete.status === 1) {
                toast.success(responseDelete.message);
                getData()
            } else {
                toast.error(responseDelete.message)
            }
        }
    }

    const LinkComponent = ({ audio }) => {
        // const generateUrl = `${baseURL}${audio}`
        // return <a href={audio} target="_blank" rel="noreferrer">
        //   {audio.split("/").slice(-1)}
        // </a>
        return <audio controls>
            <source src={audio} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
    }

    const ImageComponent = ({ image }) => {
        // const generateUrl = `${baseURL}${image}`
        return <a href={image} target="_blank" rel="noreferrer">
            <img height={50} width={50} src={image} alt={image.split("/").slice(-1)} />
        </a>
    }


    return (
        <Layout>

            <div>
                <Button className="mt-4" variant="primary" type="button" onClick={() => setModalShow(true)}>
                    Create
                </Button>

                <Modal
                    show={modalShow}
                    backdrop="static"
                    // size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {id ? "Edit" : "Create"}
                        </Modal.Title>
                    </Modal.Header>
                    {/* style={{ maxHeight: "400px", overflowY: "scroll" }} */}
                    <Modal.Body>
                        <Form onSubmit={onSubmit} id="upload-file">
                            <Form.Group>
                                <Form.Label className="mb-0 mt-1">Category</Form.Label>
                                <Form.Select onChange={e => setCategory_id(e.target.value)} value={category_id} >
                                    <option value="">Open this select menu</option>
                                    {
                                        categoriesList.length > 0 &&
                                        categoriesList.map((category, i) =>
                                            <option key={i} value={category._id}>{category.title}</option>
                                        )
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label className="mb-0 mt-1">Title</Form.Label>
                                <input type="text" className="form-control" onChange={e => setTitle(e.target.value)} value={title} placeholder="Enter Title" />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label className="mb-0 mt-1">Urdu Title</Form.Label>
                                <input type="text" className="form-control" onChange={e => setUrdu_title(e.target.value)} value={urdu_title} placeholder="Enter Urdu Title" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="mb-0 mt-1">Description</Form.Label>
                                {/* <Form.Control type="text" as="textarea" rows={3} placeholder="Enter Description" onChange={e => setDescription(e.target.value)} value={description} /> */}
                                <HTMLEditor setDescription={setDescription} description={description} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="mb-0 mt-1">Urdu Description</Form.Label>
                                {/* <Form.Control type="text" as="textarea" rows={3} placeholder="Enter Description" onChange={e => setDescription(e.target.value)} value={description} /> */}
                                <HTMLEditor setDescription={setUrdu_description} description={urdu_description} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="mb-0 mt-1">Audio file</Form.Label>
                                {
                                    existAudio ?
                                        <div className='d-flex'>
                                            <span className="p2 flex-grow-1">{existAudio}</span>
                                            <Button variant="danger" className="p2" onClick={() => setExistAudio("")}>Delete</Button>
                                        </div>
                                        :
                                        <>
                                            <Form.Control
                                                type="file"
                                                // ref={file_Ref}
                                                onChange={e => setAudio(e.target.files[0])}
                                                accept=".mp3"
                                            />
                                            <Form.Text className="text-muted mt-2">
                                                Upload audio file only.
                                            </Form.Text>
                                        </>
                                }
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="mb-0 mt-1">Urdu Audio file</Form.Label>
                                {
                                    existUrdu_audio ?
                                        <div className='d-flex'>
                                            <span className="p2 flex-grow-1">{existUrdu_audio}</span>
                                            <Button variant="danger" className="p2" onClick={() => setExistUrdu_audio("")}>Delete</Button>
                                        </div>
                                        :
                                        <>
                                            <Form.Control
                                                type="file"
                                                // ref={file_Ref}
                                                onChange={e => setUrdu_audio(e.target.files[0])}
                                                accept=".mp3"
                                            />
                                            <Form.Text className="text-muted mt-2">
                                                Upload audio file only.
                                            </Form.Text>
                                        </>
                                }
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="mb-0 mt-1">Image</Form.Label>
                                {
                                    existImage ?
                                        <div className='d-flex'>
                                            <span className="p2 flex-grow-1">{existImage}</span>
                                            <Button variant="danger" className="p2" onClick={() => setExistImage("")}>Delete</Button>
                                        </div>
                                        :
                                        <>
                                            <Form.Control
                                                type="file"
                                                // ref={image_Ref}
                                                onChange={e => setImage(e.target.files[0])}
                                                accept="image/*"
                                            />
                                            <Form.Text className="text-muted mt-2">
                                                Upload image only.
                                            </Form.Text>
                                        </>
                                }
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModel()} disabled={disable}>Close</Button>
                        <Button variant="primary" type="submit" form="upload-file" disabled={disable}>{
                            disable ?
                                <>
                                    <Spinner
                                        className='me-2'
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading...
                                </>
                                :
                                "Submit"
                        }</Button>
                    </Modal.Footer>
                </Modal>

            </div>

            <div className='mt-3'>
                <Table bordered hover responsive>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Category</th>
                            <th>Title</th>
                            <th>Urdu Title</th>
                            {/* <th>Description</th> */}
                            <th>Audio</th>
                            <th>Urdu Audio</th>
                            <th>Image</th>
                            <th>Created At</th>
                            <th>Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr>
              <th>-</th>
              <th><Form.Control type="text" placeholder="Enter name" onChange={e => setSearchName(e.target.value)} value={searchName} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th className='d-grid gap-2 d-md-flex'>
                <Button variant="primary" onClick={handleSearch}>
                  Filter
                </Button>
                <Button variant="secondary" onClick={clearSearchData}>
                  Clear
                </Button>
              </th>
            </tr> */}
                        {searchList.length > 0
                            ?
                            searchList.map((data, i) =>
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{data.category_id.title ?? "-"}</td>
                                    <td>{data.title ?? "-"}</td>
                                    <td>{data.urdu_title ?? "-"}</td>
                                    {/* <td>{data.description ?? "-"}</td> */}
                                    <td>{data.audio ? <LinkComponent audio={data.audio} /> : "-"}</td>
                                    <td>{data.urdu_audio ? <LinkComponent audio={data.urdu_audio} /> : "-"}</td>
                                    <td>{data.image ? <ImageComponent image={data.image} /> : "-"}</td>
                                    <td>{data.created_at ? getDateFormat(data.created_at) : "-"}</td>

                                    <td className='d-grid gap-2 d-md-flex'>
                                        {admin ?
                                            <>
                                                <Button variant="primary" onClick={e => editFunction(e, data)}>
                                                    Edit
                                                </Button>
                                                <Button variant="danger" onClick={e => deleteFunction(e, data._id)}>
                                                    Delete
                                                </Button>
                                            </>
                                            : "-"
                                        }
                                    </td>
                                </tr>
                            )
                            :
                            <tr>
                                <td colSpan={10} className="text-center">No record Found</td>
                            </tr>
                        }
                    </tbody>
                </Table>

                {
                    paginationData.total > 0 &&
                    <Pagination
                        className="mt-4 d-flex flex-row-reverse"
                        itemsPerPage={paginationData.perPage}
                        pagination={paginationData.total}
                        getDataFunction={getData}
                    />
                }
            </div>
        </Layout>

    )
}

export default Quran