import React from 'react'
import { Nav, Button } from 'react-bootstrap'
import {
    // Link,
    NavLink, useNavigate
} from 'react-router-dom'
import { toast } from 'react-toastify'
import { APP_NAME } from '../commonComponents/common'

const navLinks = [{
    title: 'Users',
    to: '/users'
}, {
    title: 'Subscription',
    to: '/subscription'
}, {
    title: 'Audios',
    to: '/audios'
}, {
    title: 'Categories',
    to: '/categories'
}, {
    title: 'Premiums',
    to: '/premiums'
}, {
    title: 'Categories Meditation',
    to: '/categories_meditation'
}, {
    title: 'Meditation',
    to: '/meditation'
}, {
    title: 'Mood',
    to: '/mood'
}, {
    title: 'Categories Quran',
    to: '/categories_quran'
}, {
    title: 'Quran',
    to: '/quran'
}, {
    title: 'Categories Blog',
    to: '/categories_blog'
}, {
    title: 'Blog',
    to: '/blog'
}, {
    title: 'Categories Thought',
    to: '/categories_quote'
}, {
    title: 'Thought',
    to: '/quote'
}, {
    title: 'Plan',
    to: '/plan'
}, {
    title: 'Support',
    to: '/support'
}, {
    title: 'Additional Information',
    to: '/additional-information'
}]

const Layout = ({ children }) => {

    const navigate = useNavigate()

    const logout = (e) => {
        e.preventDefault()
        localStorage.removeItem("token")
        navigate("/")
        toast.success("Logout successfully.")
    }

    return (
        <div className='p-2'>
            <div className='d-flex align-items-center justify-content-between'>
                <p className="fs-1 fw-bold mb-0">{APP_NAME}</p>
                <Button variant="danger" type="button" onClick={logout}>
                    Logout
                </Button>
            </div>
            <Nav variant="pills">
                {navLinks.map((data, i) =>
                    <Nav.Item key={i}>
                        <NavLink className="nav-link" to={data.to}>{data.title}</NavLink>
                    </Nav.Item>
                )}
                {/* <Nav.Item>
                    <Link className="nav-link" to="#" onClick={logout}>Logout</Link>
                </Nav.Item> */}
            </Nav>

            {children}
        </div>
    )
}

export default Layout