import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import serverInstance from '../APIs/API_Config';

const ProtectedRoute = ({ token, setToken, redirectPath = "/" }) => {

    // const navigate = useNavigate()
    // const [token, setToken] = useState(null)

    // useEffect(() => {
    const localToken = localStorage.getItem("token") ?? null
    // console.log(localToken);
    serverInstance.defaults.headers.common.Authorization = `Bearer ${localToken}`;
    // console.log(localToken);
    // setToken(localToken)
    // }, [navigate])

    if (!localToken) {
        setToken(null)
        console.log("1 st");
        return <Navigate to={redirectPath} replace />
    }
    else {
        setToken(localToken)
        // tokenDecode()
        console.log("2 nd");
        return <Outlet />
    }
}

export default ProtectedRoute