import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Table, Spinner } from 'react-bootstrap'
import moment from "moment";
import { toast } from 'react-toastify';
import { createAudio, deleteAudio, getAllAudio, tokenDecode, updateAudio } from '../APIs/Call_API';
import Layout from './Layout';
import { Pagination } from '../commonComponents/Pagination';
import { getDateFormat } from '../commonComponents/common';
import { baseURL } from '../APIs/API_Config';

const AudiosComponent = () => {

  const [modalShow, setModalShow] = useState(false);
  const [disable, setDisable] = useState(false)

  const getDate = (dateFormat) => moment(dateFormat).format("YYYY-MM-DD")
  const [id, setId] = useState("")
  const [date, setDate] = useState("")

  const [folder_name, setFolder_name] = useState("");

  const [existFile_1, setExistFile_1] = useState("");
  const [existFile_2, setExistFile_2] = useState("");
  const [existFile_3, setExistFile_3] = useState("");
  const [existFile_4, setExistFile_4] = useState("");
  const [existFile_5, setExistFile_5] = useState("");

  const [file_1, setFile_1] = useState(null);
  const [file_2, setFile_2] = useState(null);
  const [file_3, setFile_3] = useState(null);
  const [file_4, setFile_4] = useState(null);
  const [file_5, setFile_5] = useState(null);

  const [admin, setAdmin] = useState(false)
  const [audiosList, setAudiosList] = useState([])
  const [searchList, setSearchList] = useState([])

  const [searchName, setSearchName] = useState("")
  const [searchEmail, setSearchEmail] = useState("")
  const [paginationData, setPaginationData] = useState({})

  useEffect(() => {
    const { is_admin } = tokenDecode()
    setAdmin(is_admin)
    if (is_admin) {
      getAudiosData()
    }
  }, [])

  // console.log(file_1Ref.current);

  const getAudiosData = async (perPage = 10, page = 1, findData = {}) => {
    const requestData = {
      perPage,
      page,
      findData
    }
    const responseAudio = await getAllAudio(requestData)
    if (responseAudio.status === 1) {
      setAudiosList(responseAudio.data)
      setSearchList(responseAudio.data)
      setPaginationData(responseAudio.paginationData)
    } else {
      setAudiosList([])
      setSearchList([])
      setPaginationData({})
      // toast.error(responseAudio.message)
    }
  }

  const closeModel = () => {
    setModalShow(false)
    setId("")
    setDate("")
    setFolder_name("")
    setFile_1(null)
    setFile_2(null)
    setFile_3(null)
    setFile_4(null)
    setFile_5(null)
    setExistFile_1("")
    setExistFile_2("")
    setExistFile_3("")
    setExistFile_4("")
    setExistFile_5("")
  }

  const onSubmit = async e => {
    e.preventDefault()

    if (
      !date.trim() ||
      (!file_1?.lastModified && !existFile_1) ||
      (!file_2?.lastModified && !existFile_2) ||
      (!file_3?.lastModified && !existFile_3) ||
      (!file_4?.lastModified && !existFile_4) ||
      (!file_5?.lastModified && !existFile_5)
    ) {
      return toast.error("Some fields are missing.")
    }

    if (
      (!file_1?.type.includes("audio") && !existFile_1) ||
      (!file_2?.type.includes("audio") && !existFile_2) ||
      (!file_3?.type.includes("audio") && !existFile_3) ||
      (!file_4?.type.includes("audio") && !existFile_4) ||
      (!file_5?.type.includes("audio") && !existFile_5)
    ) {
      return toast.error("Please select only audio file.")
    }

    const formData = new FormData()
    formData.append("date", date)
    formData.append("folder_name", !folder_name ? Date.now().toString() : folder_name)
    if (!existFile_1) formData.append("file_1", file_1)
    if (!existFile_2) formData.append("file_2", file_2)
    if (!existFile_3) formData.append("file_3", file_3)
    if (!existFile_4) formData.append("file_4", file_4)
    if (!existFile_5) formData.append("file_5", file_5)
    setDisable(true)
    let responseUploadFile = null
    if (id) {   // update audio
      formData.append("id", id)
      responseUploadFile = await updateAudio(formData)
    } else {    // create audio
      responseUploadFile = await createAudio(formData)
    }
    if (responseUploadFile.status === 1) {
      toast.success(responseUploadFile.message);
      setDisable(false)
      closeModel()
      getAudiosData()
    } else {
      // toast.error(responseUploadFile.message)
      toast.error(<ErrorMessage message={responseUploadFile.message} />)
      setDisable(false)
    }
  }

  const ErrorMessage = ({ message }) => {
    const Comp = message.split(".").map(str => <p>{str}</p>);
    return <>{Comp}</>
  }

  const handleSearch = (e) => {
    e.preventDefault()
    let result = [];
    result = audiosList.filter((data) =>
      (searchName.trim() && data.name.toString().toLowerCase().includes(searchName.trim().toLowerCase()))
      ||
      (searchEmail.trim() && data.email.toString().toLowerCase().includes(searchEmail.trim().toLowerCase()))
    )
    // ||  data.loan_amount.toString().toLowerCase().includes(value)
    setSearchList(result);

  }


  /* 
    const fileInput = file_1Ref.current

    const dataTransfer = new DataTransfer()
    let blob = await fetch("http://192.168.1.10:3001/audios/2022-09-20/2022-09-20_file_2.mp3").then(r => r.blob());
    // const file = new File(['Hello world!'], 'hello.txt', { type: 'text/plain' })
    const file = new File([blob], "file_example_MP3_700KB - Copy.mp3", { type: "audio/mpeg", lastModified: 1662803184124 })


    dataTransfer.items.add(file)
    // dataTransfer.items.add(file)

    fileInput.files = dataTransfer.files
    console.log(file, file_1Ref.current.value, file_1);
    return
     */

  const showSelectedFileDataInFileInput = async (file_ref, url, setFile) => {
    const dataTransfer = new DataTransfer()
    let blob = await fetch(`${baseURL}${url}`).then(r => r.blob());
    const file = new File([blob], url.split("/")[3], { type: "audio/mpeg", lastModified: Date.now() })
    dataTransfer.items.add(file)
    file_ref.current.files = dataTransfer.files
    setFile(file)
  }

  const editAudioFunction = async (e, audioData) => {
    e.preventDefault()
    setModalShow(true)
    setId(audioData._id)
    setDate(getDate(audioData.date))
    setFolder_name(audioData.file_1.split("/").slice(-2, -1)[0])
    setExistFile_1(audioData.file_1.split("/").slice(-1)[0])
    setExistFile_2(audioData.file_2.split("/").slice(-1)[0])
    setExistFile_3(audioData.file_3.split("/").slice(-1)[0])
    setExistFile_4(audioData.file_4.split("/").slice(-1)[0])
    setExistFile_5(audioData.file_5.split("/").slice(-1)[0])
    // showSelectedFileDataInFileInput(file_1Ref, audioData.file_1, setFile_1)
    // showSelectedFileDataInFileInput(file_2Ref, audioData.file_2, setFile_2)
    // showSelectedFileDataInFileInput(file_3Ref, audioData.file_3, setFile_3)
    // showSelectedFileDataInFileInput(file_4Ref, audioData.file_4, setFile_4)
    // showSelectedFileDataInFileInput(file_5Ref, audioData.file_5, setFile_5)
  }

  const deleteAudioFunction = async (e, id) => {
    e.preventDefault()
    const isDelete = window.confirm("Do you want to delete this?")
    if (isDelete) {
      const responseDeleteAudio = await deleteAudio({ id })
      if (responseDeleteAudio.status === 1) {
        toast.success(responseDeleteAudio.message);
        getAudiosData()
      } else {
        toast.error(responseDeleteAudio.message)
      }
    }
  }

  const LinkComponent = ({ audio }) => {
    // const generateUrl = `${baseURL}${audio}`
    // return <a href={audio} target="_blank" rel="noreferrer">
    //   {audio.split("/").slice(-1)}
    // </a>
    return <audio controls style={{ width: "200px" }}>
      <source src={audio} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  }

  return (
    <Layout>

      <div>
        <Button className="mt-4" variant="primary" type="button" onClick={() => setModalShow(true)}>
          Upload
        </Button>

        <Modal
          show={modalShow}
          backdrop="static"
          // size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {id ? "Edit Audio File" : "Create Audio File"}
            </Modal.Title>
          </Modal.Header>
          {/* style={{ maxHeight: "400px", overflowY: "scroll" }} */}
          <Modal.Body className="py-0">
            <Form onSubmit={onSubmit} id="upload-file">
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="mb-0 mt-1">Date</Form.Label>
                <input type="date" className="form-control" min={getDate(new Date())} onChange={e => setDate(e.target.value)} value={date} />
                <Form.Label className="mb-0 mt-1">Audio file 1</Form.Label>

                {existFile_1 ?
                  <div className='d-flex'>
                    <span className="p2 flex-grow-1">{existFile_1}</span>
                    <Button variant="danger" className="p2" onClick={() => setExistFile_1("")}>Delete</Button>
                  </div>
                  :
                  <Form.Control
                    type="file"
                    // ref={file_1Ref}
                    onChange={e => setFile_1(e.target.files[0])}
                    accept=".mp3"
                  />
                }
                <Form.Label className="mb-0 mt-1">Audio file 2</Form.Label>
                {existFile_2 ?
                  <div className='d-flex'>
                    <span className="p2 flex-grow-1">{existFile_2}</span>
                    <Button variant="danger" className="p2" onClick={() => setExistFile_2("")}>Delete</Button>
                  </div>
                  :
                  <Form.Control type="file" onChange={e => setFile_2(e.target.files[0])} accept=".mp3" />
                }
                <Form.Label className="mb-0 mt-1">Audio file 3</Form.Label>
                {existFile_3 ?
                  <div className='d-flex'>
                    <span className="p2 flex-grow-1">{existFile_3}</span>
                    <Button variant="danger" className="p2" onClick={() => setExistFile_3("")}>Delete</Button>
                  </div>
                  :
                  <Form.Control type="file" onChange={e => setFile_3(e.target.files[0])} accept=".mp3" />
                }
                <Form.Label className="mb-0 mt-1">Audio file 4</Form.Label>
                {existFile_4 ?
                  <div className='d-flex'>
                    <span className="p2 flex-grow-1">{existFile_4}</span>
                    <Button variant="danger" className="p2" onClick={() => setExistFile_4("")}>Delete</Button>
                  </div>
                  :
                  <Form.Control type="file" onChange={e => setFile_4(e.target.files[0])} accept=".mp3" />
                }
                <Form.Label className="mb-0 mt-1">Audio file 5</Form.Label>
                {existFile_5 ?
                  <div className='d-flex'>
                    <span className="p2 flex-grow-1">{existFile_5}</span>
                    <Button variant="danger" className="p2" onClick={() => setExistFile_5("")}>Delete</Button>
                  </div>
                  :
                  <Form.Control type="file" onChange={e => setFile_5(e.target.files[0])} accept=".mp3" />
                }
                <Form.Text className="text-muted mt-2">
                  Upload audio file only.
                </Form.Text>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => closeModel()} disabled={disable}>Close</Button>
            <Button variant="primary" type="submit" form="upload-file" disabled={disable}>{
              disable ?
                <>
                  <Spinner
                    className='me-2'
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </>
                :
                "Submit"
            }</Button>
          </Modal.Footer>
        </Modal>

      </div>

      <div className='mt-3'>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>No</th>
              <th>Date</th>
              <th>Created At</th>
              <th>File 1</th>
              <th>File 2</th>
              <th>File 3</th>
              <th>File 4</th>
              <th>File 5</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <th>-</th>
              <th><Form.Control type="text" placeholder="Enter name" onChange={e => setSearchName(e.target.value)} value={searchName} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th><Form.Control type="email" placeholder="Enter email" onChange={e => setSearchEmail(e.target.value)} value={searchEmail} /></th>
              <th className='d-grid gap-2 d-md-flex'>
                <Button variant="primary" onClick={handleSearch}>
                  Filter
                </Button>
                <Button variant="secondary" onClick={clearSearchData}>
                  Clear
                </Button>
              </th>
            </tr> */}
            {searchList.length > 0
              ?
              searchList.map((data, i) =>
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{data.date ? moment(data.date).format("DD-MM-YYYY") : "-"}</td>
                  <td>{data.created_at ? getDateFormat(data.created_at) : "-"}</td>
                  <td className="p-0 py-2">{data.file_1 ? <LinkComponent audio={data.file_1} /> : "-"}</td>
                  <td className="p-0 py-2">{data.file_2 ? <LinkComponent audio={data.file_2} /> : "-"}</td>
                  <td className="p-0 py-2">{data.file_3 ? <LinkComponent audio={data.file_3} /> : "-"}</td>
                  <td className="p-0 py-2">{data.file_4 ? <LinkComponent audio={data.file_4} /> : "-"}</td>
                  <td className="p-0 py-2">{data.file_5 ? <LinkComponent audio={data.file_5} /> : "-"}</td>
                  {/* <td>{data.is_delete ? "True" : "False"}</td> */}
                  <td className='d-grid gap-2 d-md-flex'>
                    {admin ?
                      <>
                        <Button variant="primary" onClick={e => editAudioFunction(e, data)}>
                          Edit
                        </Button>
                        <Button variant="danger" onClick={e => deleteAudioFunction(e, data._id)}>
                          Delete
                        </Button>
                      </>
                      : "-"
                    }
                  </td>
                </tr>
              )
              :
              <tr>
                <td colSpan={10} className="text-center">No record Found</td>
              </tr>
            }
          </tbody>
        </Table>

        {
          paginationData.total > 0 &&
          <Pagination
            className="mt-4 d-flex flex-row-reverse"
            itemsPerPage={paginationData.perPage}
            pagination={paginationData.total}
            getDataFunction={getAudiosData}
          />
        }
      </div>

    </Layout>

  )
}

export default AudiosComponent