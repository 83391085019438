import jwt_decode from "jwt-decode";
import { DELETE, GET, PATCH, POST } from "./API_Method";

export const tokenDecode = () => {
    const token = localStorage.getItem("token")
    const decode = jwt_decode(token);
    const { is_admin, is_delete } = decode
    // console.log(decode);
    // console.log(new Date(decode.exp * 1000))
    return { is_admin, is_delete }
}

export const loginAPI = async (requestData) => {
    const { email, password } = requestData
    const response = await POST("/user/login", { email, password })
    return response
}

export const getAllUser = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/user?${result}`)
    return response
}

export const deleteUser = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/user/${id}`)
    return response
}

export const getAllAudio = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/audio?${result}`)
    return response
}

export const createAudio = async (formData) => {
    const response = await POST("/audio", formData)
    return response
}

export const updateAudio = async (formData) => {
    const response = await PATCH("/audio", formData)
    return response
}

export const deleteAudio = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/audio/${id}`)
    return response
}

export const getAllCategory = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/category?${result}`)
    return response
}

export const createCategory = async (requestData) => {
    const { name } = requestData
    const response = await POST("/category", { name })
    return response
}

export const updateCategory = async (requestData) => {
    const { id, name } = requestData
    const response = await PATCH("/category", { id, name })
    return response
}

export const deleteCategory = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/category/${id}`)
    return response
}

export const getAllPremium = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/premium?${result}`)
    return response
}

export const createPremium = async (formData) => {
    const response = await POST("/premium", formData)
    return response
}

export const updatePremium = async (formData) => {
    const response = await PATCH("/premium", formData)
    return response
}

export const deletePremium = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/premium/${id}`)
    return response
}

export const getAllMeditationCategory = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/category-meditation?${result}`)
    return response
}

export const createMeditationCategory = async (formData) => {
    const response = await POST("/category-meditation", formData)
    return response
}

export const updateMeditationCategory = async (formData) => {
    const response = await PATCH(`/category-meditation`, formData)
    return response
}

export const deleteMeditationCategory = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/category-meditation/${id}`)
    return response
}

export const getAllMeditation = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/meditation?${result}`)
    return response
}

export const createMeditation = async (formData) => {
    const response = await POST("/meditation", formData)
    return response
}

export const updateMeditation = async (formData) => {
    const response = await PATCH(`/meditation`, formData)
    return response
}

export const deleteMeditation = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/meditation/${id}`)
    return response
}

export const getAllMood = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/mood?${result}`)
    return response
}

export const createMood = async (data) => {
    const { category_id, title } = data
    const response = await POST("/mood", { category_id, title })
    return response
}

export const updateMood = async (data) => {
    const { id, category_id, title } = data
    const response = await PATCH(`/mood`, { id, category_id, title })
    return response
}

export const deleteMood = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/mood/${id}`)
    return response
}

export const getAllQuranCategory = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/category-quran?${result}`)
    return response
}

export const createQuranCategory = async (data) => {
    const { title, urdu_title, description, urdu_description, is_paid } = data
    const response = await POST("/category-quran", { title, urdu_title, description, urdu_description, is_paid })
    return response
}

export const updateQuranCategory = async (data) => {
    const { id, title, urdu_title, description, urdu_description, is_paid } = data
    const response = await PATCH(`/category-quran`, { id, title, urdu_title, description, urdu_description, is_paid })
    return response
}

export const deleteQuranCategory = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/category-quran/${id}`)
    return response
}

export const getAllQuran = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/quran?${result}`)
    return response
}

export const createQuran = async (formData) => {
    const response = await POST("/quran", formData)
    return response
}

export const updateQuran = async (formData) => {
    const response = await PATCH(`/quran`, formData)
    return response
}

export const deleteQuran = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/quran/${id}`)
    return response
}

export const getAllBlogCategory = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/category-blog?${result}`)
    return response
}

export const createBlogCategory = async (data) => {
    const { title, description, is_paid } = data
    const response = await POST("/category-blog", { title, description, is_paid })
    return response
}

export const updateBlogCategory = async (data) => {
    const { id, title, description, is_paid } = data
    const response = await PATCH(`/category-blog`, { id, title, description, is_paid })
    return response
}

export const deleteBlogCategory = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/category-blog/${id}`)
    return response
}

export const getAllBlog = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/blog?${result}`)
    return response
}

export const createBlog = async (formData) => {
    const response = await POST("/blog", formData)
    return response
}

export const updateBlog = async (formData) => {
    const response = await PATCH(`/blog`, formData)
    return response
}

export const deleteBlog = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/blog/${id}`)
    return response
}

export const getAllQuoteCategory = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/category-quotes?${result}`)
    return response
}

export const createQuoteCategory = async (data) => {
    const { title, description, is_paid } = data
    const response = await POST("/category-quotes", { title, description, is_paid })
    return response
}

export const updateQuoteCategory = async (data) => {
    const { id, title, description, is_paid } = data
    const response = await PATCH(`/category-quotes`, { id, title, description, is_paid })
    return response
}

export const deleteQuoteCategory = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/category-quotes/${id}`)
    return response
}

export const getAllQuote = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/quotes?${result}`)
    return response
}

export const createQuote = async (data) => {
    const { category_id, quotes_text, author_name } = data
    const response = await POST("/quotes", { category_id, quotes_text, author_name })
    return response
}

export const updateQuote = async (data) => {
    const { id, category_id, quotes_text, author_name } = data
    const response = await PATCH(`/quotes`, { id, category_id, quotes_text, author_name })
    return response
}

export const deleteQuote = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/quotes/${id}`)
    return response
}

export const getAllPlan = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/plan?${result}`)
    return response
}

export const createPlan = async (data) => {
    const { title, description, actual_price, price, time_period, access_module } = data
    const response = await POST("/plan", { title, description, actual_price, price, time_period, access_module })
    return response
}

export const updatePlan = async (data) => {
    const { id, title, description, actual_price, price, time_period, access_module } = data
    const response = await PATCH(`/plan`, { id, title, description, actual_price, price, time_period, access_module })
    return response
}

export const deletePlan = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/plan/${id}`)
    return response
}

export const getAllSupport = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/support?${result}`)
    return response
}

export const updateSupport = async (data) => {
    const { id, status } = data
    const response = await PATCH(`/support`, { id, status })
    return response
}

export const deleteSupport = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/support/${id}`)
    return response
}

export const getAllAdditionalInformation = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/additional-information?${result}`)
    return response
}

export const createAdditionalInformation = async (data) => {
    const { title, type, text } = data
    const response = await POST("/additional-information", { title, type, text })
    return response
}

export const updateAdditionalInformation = async (data) => {
    const { id, title, type, text } = data
    const response = await PATCH(`/additional-information`, { id, title, type, text })
    return response
}

export const deleteAdditionalInformation = async (requestData) => {
    const { id } = requestData
    const response = await DELETE(`/additional-information/${id}`)
    return response
}

export const getAllSubscription = async (requestData) => {
    const { perPage, page } = requestData
    const result = new URLSearchParams({ perPage, page }).toString()
    const response = await GET(`/subscription?${result}`)
    return response
}

// export const updateTodoAPI = async (requestData) => {
//     try {
//         const { id, username, gender, hobby, age, date, task_name, status } = requestData
//         const { data } = await PATCH("/todo", { id, username, gender, hobby, age, date, task_name, status })
//         return data
//     } catch (error) {
//         return error.response.data
//     }
// }

// export const deleteTodoAPI = async (requestData) => {
//     try {
//         const { id } = requestData
//         const { data } = await DELETE(`/todo/${id}`)
//         return data
//     } catch (error) {
//         return error.response.data
//     }
// }

// export const getAllTodoAPI = async () => {
//     try {
//         const { data } = await GET("/todo/")
//         return data
//     } catch (error) {
//         return error.response.data
//     }
// } 